//Import component JS below here
import AOS from 'aos';

import headerInit from '../components/header/header';
import menuInit from '../components/header-with-submenus/header-with-submenu';
import portfolioSlider from '../components/portfolio-slider/portfolio-slider';

$(document).foundation();

$(document).ready(function () {
    headerInit();
    menuInit();
    portfolioSlider();

    $(function() {
        var videos  = $(".video");

        videos.on("click", function(){
            var elm = $(this),
                conts   = elm.contents(),
                le      = conts.length,
                ifr     = null;

            for(var i = 0; i<le; i++){
                if(conts[i].nodeType == 8) ifr = conts[i].textContent;
            }

            elm.addClass("player").html(ifr);
            elm.off("click");

            setTimeout(function() {
                console.log(elm);
                elm.click();
            }, 1000);
        });
    });

    AOS.init({
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: 'ease-in-out', // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        disable: 'mobile'
    });
});