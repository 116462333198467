import Glide from "@glidejs/glide";

export default function() {
    if(document.querySelector('.glide-portfolio-slider') === null) {
        return;
    }
    var glide = new Glide('.glide-portfolio-slider', {
        type: 'slider',
        perView: 1,
        peek: {
            before: 0,
            after: 671
        },
        breakpoints: {
            1500: {
                peek: {
                    before: 0,
                    after: 400
                }
            },
            1100: {
                peek: {
                    before: 0,
                    after: 200
                },
            },
            500: {
                peek: {
                    before: 0,
                    after: 75
                },
            }
        }
    });

    glide.mount();
}