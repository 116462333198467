export default function() {
    if($('.has-children').length) {
        document.querySelector('.has-children').addEventListener('click', (e) => {
            if(!e.target.parentElement.classList.contains('hover')) {
                e.preventDefault();
            }

            e.target.parentElement.classList.add('hover');
        });        
    }
}